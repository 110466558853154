import { APP_ENV } from 'enum/app-env'
import Script from 'next/script'

export default function RudderStack(): React.ReactElement {
    // Disable rudderstack in non-production environments
    if (process.env.APP_ENV !== APP_ENV.PRODUCTION) {
        return null
    }
    return (
        <Script
            id="rudderstack-initialization"
            type="text/javascript"
            dangerouslySetInnerHTML={{
                __html: `
                !function(){var e=window.rudderanalytics=window.rudderanalytics||[];e.methods=["load","page","track","identify","alias","group","ready","reset","getAnonymousId","setAnonymousId"],e.factory=function(t){return function(){var r=Array.prototype.slice.call(arguments);return r.unshift(t),e.push(r),e}};for(var t=0;t<e.methods.length;t++){var r=e.methods[t];e[r]=e.factory(r)}e.loadJS=function(e,t){var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.rudderlabs.com/v1.1/rudder-analytics.min.js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a)},e.loadJS(),
                e.load("${process.env.RUDDERSTACK_WRITE_KEY}","${process.env.RUDDERSTACK_DATA_PLANE_URL}"),
                e.page()}();
            `,
            }}
        />
    )
}
