/**
 * return amount string formatted for a locale with currency
 * returned value can be different on client and server, when
 * different react gives a warning about content being different,
 * use suppressHydrationWarning={true} on individual elements to
 * suppress warning
 * @param amount number
 * @param currency string
 * @param locale string
 * @returns string
 */
export function getFormattedNumberWithCurrency(amount: number, currency: string = 'USD', locale?: string): string {
    // if locale is not provided
    // get user's locale from browser/OS
    if (!locale) {
        // check if navigator is defined
        // when this function is called by components
        // rendering server side then navigator won't
        // be defined
        if (global.navigator) {
            locale = navigator.language // this value comes from user's browser/OS
        } else {
            locale = 'en-US' // fallback locale
        }
    }

    try {
        const formattingOptions: Intl.NumberFormatOptions = {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: Number.isInteger(amount) ? 0 : 2,
        }

        const formatter = new Intl.NumberFormat(locale, formattingOptions)

        if (!isNaN(amount)) {
            return formatter.format(amount)
        }

        return `${amount}`
    } catch (e) {
        // fallback string
        return `${currency ?? ''} ${amount}`
    }
}
