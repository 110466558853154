import * as React from 'react'
import { IPrescriptionLineItem } from 'interfaces/prescription-line-item'
import { useOrder, IReturn as IUseOrderReturn } from 'hooks/useOrder'

export interface IRXCartContext {
    lineItems: IPrescriptionLineItem[]
    addLineItem: (lineItem: IPrescriptionLineItem) => void
    removeLineItem: (lineItemId: string) => void
    totalLineItem: number
    replaceLineItem: (lineItem: IPrescriptionLineItem) => void
    lineItemToHighlightId: string
    setLineItemToHighlightId: React.Dispatch<React.SetStateAction<string>>
}

type TRXCartContext = IRXCartContext & IUseOrderReturn

interface IProps {
    children: React.ReactNode
}

const RXCartContext = React.createContext<TRXCartContext>(null)

const RXCartContextProvider = ({ children }: IProps): React.ReactElement => {
    const order = useOrder()
    const [lineItems, setLineItems] = React.useState<IPrescriptionLineItem[]>([])

    const [lineItemToHighlightId, setLineItemToHighlightId] = React.useState<string>()

    const addLineItem = React.useCallback((lineItem: IPrescriptionLineItem) => {
        setLineItems((lineItems) => [...lineItems, lineItem])
    }, [])

    const removeLineItem = React.useCallback((lineItemId: string) => {
        setLineItems((lineItems) => lineItems.filter((lineItem) => lineItem.id !== lineItemId))
    }, [])

    const replaceLineItem = React.useCallback((lineItem: IPrescriptionLineItem) => {
        setLineItems((lineItems) => {
            const existingLineItemIndex = lineItems.findIndex((lt) => lt.id === lineItem.id)

            if (existingLineItemIndex === -1) {
                return lineItems
            }

            const clonedLineItems = [...lineItems]
            clonedLineItems[existingLineItemIndex] = lineItem

            return clonedLineItems
        })
    }, [])

    React.useEffect(() => {
        if (!lineItemToHighlightId) {
            return
        }

        const timer = setTimeout(() => {
            setLineItemToHighlightId(null)
        }, 2500)

        return () => {
            clearTimeout(timer)
        }
    }, [lineItemToHighlightId])

    const value: TRXCartContext = React.useMemo(
        () => ({
            lineItems,
            addLineItem,
            removeLineItem,
            totalLineItem: lineItems.length,
            replaceLineItem,
            lineItemToHighlightId,
            setLineItemToHighlightId,
            ...order,
        }),
        [lineItems, addLineItem, removeLineItem, order, replaceLineItem, lineItemToHighlightId],
    )
    return <RXCartContext.Provider value={value}>{children}</RXCartContext.Provider>
}

export const useRXCartContext = (): TRXCartContext => {
    const rxCartContext = React.useContext(RXCartContext)

    if (rxCartContext === undefined) {
        throw new Error('You cannot use useRXCartContext outside of RXCartContextProvider')
    }

    return rxCartContext
}

export default RXCartContextProvider
