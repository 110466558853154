import Router, { useRouter } from 'next/router'
import type { AppProps } from 'next/app'
import globalManifest from '../data/global-manifest.json'
import 'styles/index.css'
import { AuthContextProvider } from 'components/auth/auth'
import { ApolloContextProvider } from 'components/auth/apollo-provider'
import { HeapContextProvider, SetupHeap } from 'context/heap-context'
import UserContextProvider from 'context/user-context'
import Meta from 'components/meta'
import CartSidebar from 'components/cart/cart-sidebar'
import SecondaryNavSidebar from 'components/navigation/secondary-nav-sidebar'
import CartSidebarContextProvider from 'context/cart-sidebar-context'
import SecondaryNavSidebarContextProvider from 'context/secondary-nav-sidebar-context'
import OTCCartContextProvider from 'context/otc-cart-context'
import { useEffect } from 'react'
import BannerContextProvider from 'context/banner-context'
import { IBanner } from 'interfaces/banner'
import CustomerContextProvider from 'context/customer-context'
import GrowthBookWrapperContextProvider from 'context/growthbook-context'
import EmailCapturePopup from 'components/email-capture-popup'
import EmailCapturePopupContextProvider from 'context/email-capture-popup-context'
import RudderStack from 'components/analytics/rudderstack'
import * as Sentry from '@sentry/nextjs'
import { setItemInSS } from 'lib/util/storage'
import { GrowthbookFeaturesContextProvider } from 'context/growthbook-features-context'
import RXCartContextProvider from 'context/rx-cart-context'
import { HelpSidebarContextProvider } from 'context/help-sidebar-context'
import { ToastContextProvider } from 'context/toast-context'
import { PharmacyCardPopupContextProvider } from 'context/pharmacy-card-popup-context'
import Redirects from 'components/redirects'
import { ProfileContextProvider } from 'context/profile-context'

const { resources, banners } = globalManifest

const onRedirectCallback = (appState): void => {
    if (appState?.returnTo) Router.replace(appState?.returnTo)
}

function CabinetApp({ Component, pageProps }: AppProps & { banners: IBanner[] }): JSX.Element {
    const router = useRouter()
    const { query } = router

    useEffect(() => {
        SetupHeap()
    }, [])

    // Refresh the growthbook visual editor settings
    useEffect(() => {
        const onChange = () => window.growthbook?.refresh()
        router.events.on('routeChangeComplete', onChange)

        // Cleanup on dismount
        return () => router.events.off('routeChangeComplete', onChange)
    }, [router.events])

    useEffect(() => {
        if (!query) {
            return
        }

        try {
            // add utm query params in session storage
            for (const key in query) {
                if (key.startsWith('utm_')) {
                    setItemInSS(key, query[key] as string)
                }
            }
        } catch (e) {
            Sentry.captureException(e)
        }
    }, [query])

    return (
        <>
            <Redirects />
            <HeapContextProvider>
                <GrowthbookFeaturesContextProvider>
                    <AuthContextProvider
                        domain={process.env.AUTH0_PUBLIC_DOMAIN}
                        client_id={process.env.AUTH0_PUBLIC_CLIENT_ID}
                        audience={process.env.AUTH0_PUBLIC_AUDIENCE}
                        redirect_uri={typeof window !== 'undefined' && window.location.origin}
                        onRedirectCallback={onRedirectCallback}
                    >
                        <ApolloContextProvider>
                            <RudderStack />
                            <CustomerContextProvider>
                                <GrowthBookWrapperContextProvider>
                                    <UserContextProvider>
                                        <ProfileContextProvider>
                                            <ToastContextProvider>
                                                <SecondaryNavSidebarContextProvider>
                                                    <CartSidebarContextProvider>
                                                        <OTCCartContextProvider>
                                                            <RXCartContextProvider>
                                                                <Meta uiResources={resources} />
                                                                <HelpSidebarContextProvider>
                                                                    <PharmacyCardPopupContextProvider>
                                                                        <div className="cabinet-container md:border-r md:border-l lg:border-r-0 lg:border-l-0 2xl:border-r 2xl:border-l border-gray box-content">
                                                                            <BannerContextProvider
                                                                                // @ts-ignore
                                                                                banners={banners}
                                                                            >
                                                                                <EmailCapturePopupContextProvider>
                                                                                    <Component {...pageProps} />
                                                                                    <EmailCapturePopup />
                                                                                </EmailCapturePopupContextProvider>
                                                                            </BannerContextProvider>
                                                                        </div>
                                                                        <SecondaryNavSidebar />
                                                                    </PharmacyCardPopupContextProvider>
                                                                </HelpSidebarContextProvider>

                                                                <CartSidebar uiResources={resources} />
                                                            </RXCartContextProvider>
                                                        </OTCCartContextProvider>
                                                    </CartSidebarContextProvider>
                                                </SecondaryNavSidebarContextProvider>
                                            </ToastContextProvider>
                                        </ProfileContextProvider>
                                    </UserContextProvider>
                                </GrowthBookWrapperContextProvider>
                            </CustomerContextProvider>
                        </ApolloContextProvider>
                    </AuthContextProvider>
                </GrowthbookFeaturesContextProvider>
            </HeapContextProvider>
        </>
    )
}

export default CabinetApp
