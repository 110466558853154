// internal names
export const helpNavigationMobileMegaMenuInternalName = 'Mobile mega menu - help'
export const pharmacyCardNavigationMenuItemInternalName = 'Pharmacy Card (Pharmacy card popup)'
export const helpPanelNavigationMenuItemInternalName = 'Contact Us (open help panel link)'
export const helpFooterNavigationInternalName = 'Footer help'
export const connectNavigtionInternalName = 'Connect'
export const accountPortalAddPrescriptionLinkInternalName = 'Account portal add prescription'
export const profileToggleNavigationMobileMenuInternalName = 'Profile Toggle'
export const postPaymentFlowInternalName = 'Post payment flow'
export const refillsRemainingButtonInternalName = 'Rx - Onboarding - Refills remaining'
export const noRefillsRemainingButtonInternalName = 'Rx - Onboarding - No refills'
export const medicationNeverTakenButtonInternalName = 'Rx - Onboarding - Never taken medication'

// Specific to the Target Page
export const visualizerSectionMissionInternalName = 'Mission'
export const visualizerSectionOurProgressInternalName = 'Our Progress'

// Specific to the CVS Page
export const iconSectionSustainableTourBannerInternalName = 'Sustainable Tour Banner'
export const genericSectionCabinetCVSInternalName = 'Cabinet Health x CVS'
export const iconSectionOurPartnersInternalName = 'Our Partners'
export const visualizerSectionOurMissionInternalName = 'Our Mission'
export const visualizerSectionProgressInternalName = 'Progress'
export const visualizerWidgetCardsInternalName = 'Widget Cards [CVS]'
