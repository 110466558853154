// pathname (as exposed from useRouter().pathname)
export const homePagePath = '/'
export const accountPagePath = '/account'
export const blogCategoryHomePagePath = '/blogs'
export const blogCategorySubPagePath = '/blogs/[slug]'

// slugs
export const postPaymentFlowSlug = 'rx-onboarding-post-payment'
export const rxNonContextFlowSlug = 'rx-get-started'
export const rxContextFlowSlug = 'rx-get-started-ctx'

// urls
export const postPaymentFlowUrl = `/flow/${postPaymentFlowSlug}`
