const track = (identity: string, actionName: string, action?: Record<string, any>) => {
    const _tvq = (window._tvq = window._tvq || [])
    const session = { user: identity }
    const u = 'https://collector-45484.tvsquared.com/'
    _tvq.push(['setSiteId', 'TV-5445541854-1'])
    _tvq.push(['setTrackerUrl', u + 'tv2track.php'])
    _tvq.push([
        function () {
            this.setCustomVariable(5, 'session', JSON.stringify(session), 'visit')
        },
    ])
    if (action) {
        _tvq.push([
            function () {
                this.setCustomVariable(5, actionName, JSON.stringify(action), 'page')
            },
        ])
    }
    _tvq.push(['trackPageView'])
}

const tvq = {
    track,
}

export default tvq
