/**
 * Allows for the use of multiple signals in fetch requests.
 * Uses AbortSignal.any if it is available else it takes
 * the list of signals and returns a single new signal that
 * acts as a single for all signals (let's call is the main signal).
 * Whenever any one signal is aborted the main signal is aborted.
 */
export function anySignal(signals: Iterable<AbortSignal>): AbortSignal {
    const controller = new AbortController()

    if ('any' in AbortSignal) {
        // @ts-ignore
        return AbortSignal.any(signals)
    }

    for (const signal of signals) {
        if (signal.aborted) {
            controller.abort(signal.reason)
            return signal
        }

        signal.addEventListener('abort', () => controller.abort(signal.reason), {
            signal: controller.signal,
        })
    }

    return controller.signal
}
