import { gql } from '@apollo/client/core'
import { fetchBackend } from './backend'
import { getObjectRemoveNils } from 'utils/data-utils'

/**
 * Use the multipass token to get an access token.
 * This is a frontend-only request
 */

export const GET_SHOPIFY_ACCESS_TOKEN = gql`
    mutation CustomeAccessToken($multipassToken: String!) {
        customerAccessTokenCreateWithMultipass(multipassToken: $multipassToken) {
            customerAccessToken {
                accessToken
                expiresAt
            }
            customerUserErrors {
                code
                field
                message
            }
        }
    }
`

// Use this query in the front end
export const CLIENT_SIDE_USER_GRAPHQL_QUERY = gql`
    query GetUser {
        user {
            id
            first_name
            last_name
            email
            phone_number
            nickname
            date_of_birth
        }
    }
`

export interface IUserMembershipQuery {
    email: string
}

const userMembershipStatusCache = {}

export async function fetchUserMemberhsip(
    userMembershipQuery: IUserMembershipQuery,
): Promise<{ data: { has_membership: boolean }; error: boolean }> {
    const cachedData = userMembershipStatusCache[JSON.stringify(userMembershipQuery)]

    if (cachedData) {
        return { data: cachedData, error: false }
    }

    const userMembershipEndpoint =
        `/api/customer/membership-check/?` +
        new URLSearchParams(getObjectRemoveNils(userMembershipQuery) as Record<string, any>)

    try {
        const resp = await fetchBackend(userMembershipEndpoint)
        if (resp.ok) {
            const data = await resp.json()
            userMembershipStatusCache[JSON.stringify(userMembershipQuery)] = data
            return { data, error: false }
        } else {
            return {
                data: undefined,
                error: !resp.ok,
            }
        }
    } catch (error) {
        return {
            data: undefined,
            error,
        }
    }
}
