import { createContext, useContext, useMemo, useEffect } from 'react'
import { useRouter } from 'next/router'
import * as Sentry from '@sentry/browser'
import { useHeap } from 'context/heap-context'
import { ICustomerContext } from 'interfaces/customer'
import tvq from 'lib/api/tvsquared'

export const NEW_CUSTOMER_KEY = 'New Customer'

// Temporary mapping - if all works well with Rudder stack we'll adopt those standard names.
export const ADD_TO_CART_EVENT_NAME = 'AddToCart'
export const RUDDERSTACK_ADD_TO_CART_EVENT_NAME = 'Product Added'
export const RUDDERSTACK_PRODUCT_VIEWED_EVENT_NAME = 'Product Viewed'

// Custom Events
export const STORE_LOCATOR_SELECT_SEARCH_RESULT_EVENT_NAME = 'Store locator - Select - Search result'
export const STORE_LOCATOR_SELECT_CLICK_GET_DIRECTIONS_EVENT_NAME = 'Store locator - Click - Get directions'

// Purchase Related Events
export const PURCHASE_EVENT_NAME = 'Purchase'
export const FLOW_ORDER_CONFIRMATION_VIEWED_WITH_VALID_ORDER_EVENT_NAME = 'Flow - Viewed - Paid Order Confirmation'

const FB_PIXEL_STANDARD_EVENTS = [
    'AddPaymentInfo',
    ADD_TO_CART_EVENT_NAME,
    'AddToWishlist',
    'CompleteRegistration',
    'Contact',
    'CustomizeProduct',
    'Donate',
    'FindLocation',
    'InitiateCheckout',
    'Lead',
    'PageView',
    PURCHASE_EVENT_NAME,
    'Schedule',
    'Search',
    'StartTrial',
    'SubmitApplication',
    'Subscribe',
    'ViewContent',
]

export interface IProps {
    children: React.ReactNode
}

// Meta Pixel line items format
const CustomerContext = createContext(null)

/**
 * Use this context provider to track customer behavior.
 * You can think of it as "Analytics" --> Heap/GA/Facebook Pixel
 * but a bit more generic so it also works for operational data;
 * for example, sending cart data into our email provider to send abandoned carts
 */
const CustomerContextProvider = ({ children }: IProps): React.ReactElement => {
    const { loading: heapLoading, identity, setIdentity, userId } = useHeap()
    const router = useRouter()

    // Monitor client side page transitions
    useEffect(() => {
        const handleRouteChange = (url) => {
            if (window.rudderanalytics) {
                const properties = {
                    page: url,
                    $is_session_activity: true, // Required for Klaviyo to mark this as "Active on Site"
                }
                window.rudderanalytics.track('View page', properties)
            }
        }

        router.events.on('routeChangeStart', handleRouteChange)

        // If the component is unmounted, unsubscribe
        // from the event with the `off` method:
        return () => {
            router.events.off('routeChangeStart', handleRouteChange)
        }
    }, [router])

    const value = useMemo(() => {
        /**
         * Send event tracking data where ever we need it
         *
         * Currently sends events to the following platforms
         *   - Heap
         *   - Facebook Pixel
         *   - RudderStack
         */
        const track: ICustomerContext['track'] = (eventName, eventProperties) => {
            // Heap
            const heapEventProperties = {}
            Object.keys(eventProperties ?? {}).forEach((eventPropKey: string) => {
                if (eventProperties[eventPropKey] instanceof Array) {
                    heapEventProperties[eventPropKey] = JSON.stringify(eventProperties[eventPropKey])
                } else {
                    heapEventProperties[eventPropKey] = eventProperties[eventPropKey]
                }
            })
            if (!heapLoading && window?.heap?.track) {
                try {
                    window?.heap?.track(eventName, heapEventProperties)
                } catch {
                    // Likely an ad-blocker or some other issue loading the Heap library
                    // This won't impact the user so nothing else to do
                }
            }

            // Facebook
            if (window && window?.fbq) {
                const trackMethod = FB_PIXEL_STANDARD_EVENTS.includes(eventName) ? 'track' : 'trackCustom'
                switch (eventName) {
                    case FLOW_ORDER_CONFIRMATION_VIEWED_WITH_VALID_ORDER_EVENT_NAME:
                        // Convert this to a "Purchase" conversion eventName for Meta
                        window.fbq(trackMethod, PURCHASE_EVENT_NAME, {
                            ...eventProperties,
                            // mapping this value to eventID for FB de-duplication
                            eventID: eventProperties.order_id,
                        })
                        break
                    // All other events
                    default:
                        window.fbq(trackMethod, eventName, eventProperties)
                }
            }

            // Google (GA4 tag)
            if (window && window.gtag) {
                if (eventName === FLOW_ORDER_CONFIRMATION_VIEWED_WITH_VALID_ORDER_EVENT_NAME) {
                    window.gtag('event', 'conversion_event_purchase', {
                        value: eventProperties.value,
                        currency: 'USD',
                        transaction_id: eventProperties.order_id,
                    })
                }
            }

            // TVSquared - Only fire for purchase right now.
            if (eventName === FLOW_ORDER_CONFIRMATION_VIEWED_WITH_VALID_ORDER_EVENT_NAME) {
                const actionName = 'Purchase (order confirmation pageview)'
                const action = {
                    rev: eventProperties.value,
                    // Not sure if this is the format we're going to stick with; but putting it in for now
                    prod: eventProperties.contents.map((content) => JSON.stringify(content)).join(', '),
                    id: eventProperties.order_id,
                    promo: ' ',
                }

                tvq.track(identity, actionName, action)
            }

            // Rudderstack
            if (window && window?.rudderanalytics) {
                // Remapping select event names and formats to the standard expected by RudderStack
                // Eventually we'll be able to make these the standard and RudderStack will forward
                // them onto the respective trackers. Keeping the changes small for now while we test.
                // https://www.rudderstack.com/docs/event-spec/ecommerce-events-spec/
                if (eventName === ADD_TO_CART_EVENT_NAME) {
                    // Note - Rudder stack standard events only support one item per Add to Cart event
                    // This means we might send multiple ids in the "product_id" field if a bundle was added
                    window.rudderanalytics.track(RUDDERSTACK_ADD_TO_CART_EVENT_NAME, {
                        product_id: eventProperties.content_ids.toString(),
                        name: eventProperties.content_name,
                        price: eventProperties.value,
                        currency: eventProperties.currency,
                    })
                }
                // Send along all other events to RudderStack
                else {
                    window.rudderanalytics.track(eventName, eventProperties)
                }
            }
        }

        /**
         * Identify an anonymous customer/user
         *
         * Currently sends events to the following platforms
         *   - Heap
         *   - RudderStack
         *   - Sentry
         */
        const identify = (email: string) => {
            // Identify in Heap
            if (!heapLoading) {
                try {
                    window?.heap.identify(email)
                } catch {
                    // Likely an ad-blocker or some other issue loading the Heap library
                    // This won't impact the user so nothing else to do
                }
            }

            // Identify in Rudderstack
            if (window?.rudderanalytics) {
                try {
                    window.rudderanalytics.identify(email, { email })
                } catch {
                    // Likely an ad-blocker or some other issue loading the Heap library
                    // This won't impact the user so nothing else to do
                }
            }

            // Identify with Sentry
            Sentry.setUser({ email, id: userId })

            // Also keeping track of this in heap context state
            setIdentity(email)
        }

        /**
         * Clear any session event properties we might have added
         *
         * Currently sends events to the following platforms
         *   - Heap
         */
        const clearEventProperties = () => {
            // Identify in Heap
            if (!heapLoading) {
                try {
                    window?.heap?.clearEventProperties()
                } catch {
                    // Likely an ad-blocker or some other issue loading the Heap library
                    // This won't impact the user so nothing else to do
                }
            }
        }

        /**
         * Add properties to every event of this session
         *
         * Currently sends events to the following platforms
         *   - Heap
         */
        const addEventProperties = (eventProperties: Record<string, string | number>) => {
            // Identify in Heap
            if (!heapLoading) {
                try {
                    window?.heap?.addEventProperties(eventProperties)
                } catch {
                    // Likely an ad-blocker or some other issue loading the Heap library
                    // This won't impact the user so nothing else to do
                }
            }
        }

        return {
            loading: heapLoading,
            userId,
            identity,
            track,
            identify,
            addEventProperties,
            clearEventProperties,
        }
    }, [heapLoading, setIdentity, identity, userId])

    return <CustomerContext.Provider value={value}>{children}</CustomerContext.Provider>
}

export const useCustomerContext = (): ICustomerContext => {
    const customerContext = useContext(CustomerContext)

    if (typeof customerContext === 'undefined') {
        throw new Error('You cannot use CustomerContext outside CustomerContextProvider')
    }

    return customerContext
}

export default CustomerContextProvider
